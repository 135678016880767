import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// core components
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import 'assets/scss/material-kit-react.scss'

const Layout = ({ activeDocMeta, menu, tours, children }) => {
  return (
    <>
      <Header
        color="transparent"
        brand="SERVITOURS"
        fixed
        changeColorOnScroll={{
          height: 200,
          color: 'white',
        }}
        activeDocMeta={activeDocMeta}
        menu={menu}
        tours={tours}
      />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
