import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ description, meta, image: metaImage, title, publishDate }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          author
          description
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || data.site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          name: 'author',
          content: data.site.siteMetadata.author,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          name: 'twitter:creator',
          content: data.site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'date',
          content: publishDate,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  name: 'image',
                  property: 'og:image',
                  content: metaImage.url,
                },
                {
                  property: 'og:image:width',
                  content: metaImage.dimensions.width,
                },
                {
                  property: 'og:image:height',
                  content: metaImage.dimensions.height,
                },
                {
                  name: 'twitter:image',
                  content: metaImage.url,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ],
        )
        .concat(meta)}
    />
  );
};

SEO.defaultProps = {
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.object,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  publishDate: PropTypes.string,
};

export default SEO;
