import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import headerIconsStyle from 'assets/jss/material-kit-react/components/headerIconsStyle';

const ServicesIcon = ({ classes }) => {
  return (
    <SvgIcon viewBox="0 0 512 512" className={classes.icon}>
      <g>
        <path
          className={classes.fillWhite}
          d="M315.469,401.067l97.638-69.743c11.119-7.953,26.573-5.385,34.526,5.734  c0.393,0.546,0.759,1.109,1.101,1.681l0,0c6.665,11.093,3.652,25.446-6.903,32.93l-103.62,85.888  c-9.182,7.612-20.736,11.776-32.666,11.776H93.602v-102.4l41.702-34.756c6.135-5.111,13.867-7.91,21.854-7.91h111.377  c11.785,0,21.333,9.549,21.333,21.333l0,0c0,11.785-9.549,21.333-21.333,21.333h-55.467c-9.429,0-17.067,7.637-17.067,17.067l0,0  c0,9.429,7.637,17.067,17.067,17.067H315.469z"
          data-original="#FEC9A3"
          data-old_color="#FEC9A3"
        />
        <rect
          y="358.4"
          className={classes.fillWhite}
          width="93.602"
          height="145.067"
          data-original="#4398D1"
          data-old_color="#4398D1"
        />
        <circle
          className={classes.fillWhite}
          cx="42.667"
          cy="452.267"
          r="17.067"
          data-original="#E5E5E5"
          data-old_color="#E5E5E5"
        />
        <path
          className={classes.fillGrey}
          d="M8.533,273.067h494.933l0,0l0,0c0,28.279-22.921,51.2-51.2,51.2H59.733  C31.454,324.267,8.533,301.346,8.533,273.067L8.533,273.067L8.533,273.067z"
          data-original="#D1D1D1"
        />
        <path
          className={classes.fillWhite}
          d="M469.333,273.067H42.667v-8.533C42.667,146.714,138.18,51.2,256,51.2l0,0  c117.82,0,213.333,95.514,213.333,213.333V273.067z"
          data-original="#E5E5E5"
          data-old_color="#E5E5E5"
        />
        <g>
          <path
            className={classes.fillWhite}
            d="M113.493,170.735l-14.242-9.387c4.958-7.492,10.445-14.618,16.418-21.333l12.757,11.332   C122.999,157.449,118.007,163.925,113.493,170.735z"
            data-original="#FFFFFF"
          />
          <path
            className={classes.fillWhite}
            d="M139.247,140.16l-11.682-12.433C162.304,94.976,208.256,76.749,256,76.8v17.067   C212.599,93.824,170.829,110.387,139.247,140.16z"
            data-original="#FFFFFF"
          />
        </g>
        <polygon
          className={classes.fillGrey}
          points="281.6,0 230.4,0 230.4,17.067 247.467,17.067 247.467,51.2 264.533,51.2 264.533,17.067   281.6,17.067 "
          data-original="#D1D1D1"
        />
        <path
          className={classes.fillDarkerGrey}
          d="M380.169,273.067c-47.505,24.166-98.227,41.404-150.622,51.2h222.72c28.279,0,51.2-22.921,51.2-51.2  H380.169z"
          data-original="#C9C9C9"
        />
        <path
          className={classes.fillLighterGrey}
          d="M469.333,264.533c0.051-58.718-24.166-114.842-66.927-155.085  c-59.853,85.427-168.209,136.457-245.299,163.618h312.226V264.533z"
          data-original="#DEDEDE"
        />
        <g>
          <path
            className={classes.fillWhite}
            d="M449.971,341.333c-0.358-0.879-0.777-1.732-1.237-2.56c-7.023-11.725-22.221-15.539-33.946-8.516   c-0.572,0.341-1.135,0.708-1.681,1.101l-14.012,9.975H449.971z"
            data-original="#F7BB8F"
            data-old_color="#F7BB8F"
          />
          <path
            className={classes.fillWhite}
            d="M289.434,341.333c-2.031-9.933-10.761-17.067-20.898-17.067H157.158   c-7.987,0-15.718,2.799-21.854,7.91l-10.982,9.156H289.434z"
            data-original="#F7BB8F"
            data-old_color="#F7BB8F"
          />
        </g>
        <path
          d="M68.267,452.267c0-14.14-11.46-25.6-25.6-25.6s-25.6,11.46-25.6,25.6s11.46,25.6,25.6,25.6S68.267,466.406,68.267,452.267  L68.267,452.267z M34.133,452.267c0-4.71,3.823-8.533,8.533-8.533s8.533,3.823,8.533,8.533s-3.823,8.533-8.533,8.533  S34.133,456.977,34.133,452.267z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M503.467,264.533h-25.6C477.722,145.391,383.59,47.582,264.533,42.88V17.067H281.6V0h-51.2v17.067h17.067V42.88  C128.41,47.582,34.278,145.391,34.133,264.533h-25.6c-4.71,0-8.533,3.823-8.533,8.533c0.034,32.973,26.761,59.699,59.733,59.733  h61.491l-22.63,18.859c-1.425-1.126-3.174-1.749-4.992-1.792H0v17.067h85.069v128H0V512h93.602c4.71,0,8.533-3.823,8.533-8.533  v-25.6h203.409c13.918-0.009,27.392-4.873,38.11-13.747l103.117-85.487c14.123-10.112,18.15-29.355,9.276-44.288  c-0.358-0.606-0.853-1.101-1.254-1.707c31.94-1.348,57.148-27.605,57.207-59.571C512,268.356,508.177,264.533,503.467,264.533z   M256,59.733c113.05,0.128,204.672,91.75,204.8,204.8H51.2C51.328,151.484,142.95,59.861,256,59.733z M436.386,365.107  L332.8,450.987c-7.663,6.34-17.306,9.813-27.255,9.813H102.135v-89.865l38.665-32.162c4.599-3.84,10.394-5.956,16.384-5.973h111.351  c7.066,0,12.8,5.734,12.8,12.8c0,7.066-5.734,12.8-12.8,12.8h-55.467c-14.14,0-25.6,11.46-25.6,25.6c0,14.14,11.46,25.6,25.6,25.6  h102.4c1.775,0,3.516-0.563,4.958-1.596l97.638-69.734c7.279-5.214,17.408-3.541,22.622,3.738c0.256,0.358,0.503,0.734,0.734,1.118  C445.781,350.635,443.58,360.243,436.386,365.107z M295.407,332.8h100.958l-83.627,59.733h-99.669c-4.71,0-8.533-3.823-8.533-8.533  s3.823-8.533,8.533-8.533h55.467c16.495,0,29.867-13.372,29.867-29.867C298.377,341.163,297.353,336.785,295.407,332.8z   M452.267,315.733H59.733c-20.267-0.026-37.734-14.276-41.813-34.133h476.16C490.001,301.457,472.533,315.708,452.267,315.733z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M99.243,161.331l14.242,9.387c4.514-6.827,9.506-13.321,14.933-19.439l-12.757-11.332  C109.687,146.671,104.201,153.822,99.243,161.331z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M139.247,140.16C170.829,110.387,212.599,93.824,256,93.867V76.8c-47.744-0.051-93.696,18.176-128.435,50.927  L139.247,140.16z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
      </g>
    </SvgIcon>
  );
};

export default withStyles(headerIconsStyle)(ServicesIcon);
