/*eslint-disable*/
import React, { useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { RichText } from 'prismic-reactjs';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// core/components
import AboutIcon from 'components/Header/Icons/AboutIcon';
import ContactIcon from 'components/Header/Icons/ContactIcon';
import HomeIcon from 'components/Header/Icons/HomeIcon';
import NewsIcon from 'components/Header/Icons/NewsIcon';
import ServicesIcon from 'components/Header/Icons/ServicesIcon';
import FAQIcon from 'components/Header/Icons/FAQIcon';

import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

import headerLinksStyle from 'assets/jss/material-kit-react/components/headerLinksStyle';

const HeaderLinks = ({ activeDocMeta, classes, menu }) => {

  const renderHeaderLinks = menu.links ? menu.links.map((menuLink, index) => {
    let menuLinkIcon =  <HomeIcon />;

    switch (menuLink.link.type) {
      case "homepage":
        menuLinkIcon = <HomeIcon />;
        break;

      case "about_page":
        menuLinkIcon = <AboutIcon />;
        break;

      case "contact_page":
        menuLinkIcon = <ContactIcon />;
        break;

      case "faq_page":
        menuLinkIcon = <FAQIcon />;
        break;

      case "news_page":
        menuLinkIcon = <NewsIcon />;
        break;

      case "services_page":
        menuLinkIcon = <ServicesIcon />;
        break;
    
      default:
        menuLinkIcon = <HomeIcon />;
        break;
    }
    return (
    <ListItem className={classes.listItem} key={`top-nav-${index}`}>
      <AniLink to={menuLink.link.url} className={classes.navLink} paintDrip hex="#819E84">
        <span>{menuLinkIcon}</span>
        {RichText.asText(menuLink.label.raw)}
      </AniLink>
    </ListItem>);
  }) : null;

  return (
    <List className={classes.list}>
      {renderHeaderLinks}
      <ListItem className={classes.listItem}>
        <LanguageSwitcher activeDocMeta={activeDocMeta} />
      </ListItem>
    </List>
  );
};

export default withStyles(headerLinksStyle)(HeaderLinks);
