const headerIconsStyle = theme => ({
  icon: {
    width: '20px',
    height: '20px',
    marginRight: '4px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '14px',
    },
  },
  fillWhite: {
    fill: '#fff',
  },
  fillGrey: {
    fill: '#d1d1d1',
  },
  fillLighterGrey: {
    fill: '#dedede',
  },
  fillDarkerGrey: {
    fill: '#c9c9c9',
  },
  fillPurple: {
    fill: '#b17acc',
  },
});

export default headerIconsStyle;
