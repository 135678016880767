// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes
const { defaultLanguage } = require('../../prismic-config');

const linkResolver = doc => {
  // Route for homepage
  if (doc.type === 'homepage') {
    return doc.lang === defaultLanguage ? '/' : `/${doc.lang}`;
  }

  // Route for news article
  if (doc.type === 'news_article') {
    return doc.lang === defaultLanguage
      ? `/news/article/${doc.uid}`
      : `/${doc.lang}/news/article/${doc.uid}`;
  }
  // Route for destinations
  if (doc.type === 'destination') {
    return doc.lang === defaultLanguage
      ? `/destination/${doc.uid}`
      : `/${doc.lang}/destination/${doc.uid}`;
  }

  if (doc.type === 'news_page') {
    return doc.lang === defaultLanguage ? `/news` : `/${doc.lang}/news`;
  }

  if (doc.type === 'about_page') {
    return doc.lang === defaultLanguage ? `/about` : `/${doc.lang}/about`;
  }

  if (doc.type === 'services_page') {
    return doc.lang === defaultLanguage ? `/services` : `/${doc.lang}/services`;
  }

  if (doc.type === 'success_page') {
    return doc.lang === defaultLanguage ? `/success` : `/${doc.lang}/success`;
  }

  if (doc.type === 'faq_page') {
    return doc.lang === defaultLanguage ? `/faq` : `/${doc.lang}/faq`;
  }

  if (doc.type === 'contact_page') {
    return doc.lang === defaultLanguage ? `/contact` : `/${doc.lang}/contact`;
  }

  // default route fallback
  return '/';
};

module.exports = linkResolver;
