import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import headerIconsStyle from 'assets/jss/material-kit-react/components/headerIconsStyle';

const ContactIcon = ({ classes }) => {
  return (
    <SvgIcon viewBox="0 0 512 512" className={classes.icon}>
      <g>
        <path
          id="SVGCleanerId_0"
          className={classes.fillGrey}
          d="M201.972,10.001h280.025c11.047,0,20.002,8.955,20.002,20.002v156.014  c0,11.047-8.955,20.002-20.002,20.002H201.972c-11.047,0-20.002-8.955-20.002-20.002V30.003  C181.97,18.956,190.926,10.001,201.972,10.001z"
          data-original="#C1EE92"
          data-old_color="#C1EE92"
        />
        <g>
          <path
            id="SVGCleanerId_0_1_"
            className={classes.fillGrey}
            d="M201.972,10.001h280.025c11.047,0,20.002,8.955,20.002,20.002v156.014   c0,11.047-8.955,20.002-20.002,20.002H201.972c-11.047,0-20.002-8.955-20.002-20.002V30.003   C181.97,18.956,190.926,10.001,201.972,10.001z"
            data-original="#C1EE92"
            data-old_color="#C1EE92"
          />
        </g>
        <path
          className={classes.fillLighterGrey}
          d="M481.997,10.001H201.972c-4.136,0-7.98,1.256-11.169,3.407l151.182,109.103L493.398,13.58  C490.163,11.329,486.238,10.001,481.997,10.001z"
          data-original="#E5F8D2"
          data-old_color="#E5F8D2"
        />
        <path
          className={classes.fillDarkerGrey}
          d="M291.613,476.326l12.608-12.608c11.241-11.241,11.241-29.468,0-40.709l-52.695-52.695  c-11.241-11.241-29.468-11.241-40.709,0l-3.434,3.434c-8.682,8.682-22.757,8.682-31.439,0l-37.693-37.693  c-8.682-8.682-8.682-22.757,0-31.439l3.434-3.434c11.241-11.241,11.241-29.468,0-40.709l-52.695-52.695  c-11.241-11.241-29.468-11.241-40.709,0l-12.608,12.608c-34.292,34.292-34.292,89.891,0,124.184l131.755,131.755  C201.721,510.619,257.32,510.619,291.613,476.326z"
          data-original="#FF5D5D"
          data-old_color="#FF5D5D"
        />
        <g>
          <path
            className={classes.fillWhite}
            d="M131.765,319.83c0.125-5.518,2.275-11.001,6.487-15.212l3.434-3.434   c11.241-11.241,11.241-29.468,0-40.709L88.991,207.78c-11.241-11.241-29.468-11.241-40.709,0l-12.608,12.608   c-0.562,0.562-1.09,1.145-1.633,1.718L131.765,319.83z"
            data-original="#FFFFFF"
            data-old_color="#FFFFFF"
          />
          <path
            className={classes.fillWhite}
            d="M304.221,423.009l-52.695-52.694c-11.241-11.241-29.468-11.241-40.709,0l-3.434,3.434   c-4.211,4.211-9.694,6.362-15.212,6.487l97.723,97.724c0.573-0.544,1.156-1.072,1.718-1.634l12.608-12.608   C315.462,452.477,315.462,434.25,304.221,423.009z"
            data-original="#FFFFFF"
            data-old_color="#FFFFFF"
          />
        </g>
        <path
          d="M325.983,196.018c-2.63,0-5.21,1.069-7.071,2.93c-1.86,1.86-2.93,4.44-2.93,7.071c0,2.63,1.07,5.21,2.93,7.07  c1.86,1.86,4.44,2.931,7.071,2.931s5.21-1.07,7.071-2.931c1.86-1.859,2.93-4.439,2.93-7.07c0-2.641-1.07-5.21-2.93-7.071  C331.194,197.088,328.613,196.018,325.983,196.018z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M499.488,5.649c-0.112-0.086-0.229-0.164-0.344-0.245C494.28,2.003,488.37,0,481.997,0H201.972  c-5.974,0-11.539,1.761-16.22,4.782c-0.372,0.218-0.731,0.46-1.077,0.728c-7.679,5.439-12.706,14.386-12.706,24.492v156.014  c0,16.543,13.459,30.003,30.003,30.003h74.007c5.523,0,10.001-4.478,10.001-10.001c0-5.522-4.477-10.001-10.001-10.001h-64.197  l82.079-95.902l42.271,30.505c1.747,1.26,3.799,1.891,5.853,1.891c2.048,0,4.096-0.628,5.841-1.883l42.332-30.455l82.031,95.844  h-102.2c-5.524,0-10.001,4.478-10.001,10.001c0,5.523,4.477,10.001,10.001,10.001h112.01c16.543,0,30.003-13.459,30.003-30.003  V30.003C512,19.983,507.057,11.101,499.488,5.649z M192.202,188.136c-0.148-0.684-0.231-1.391-0.231-2.119V30.003  c0-1.07,0.173-2.099,0.486-3.067l85.131,61.436L192.202,188.136z M341.995,110.185L217.03,20.002h250.317L341.995,110.185z   M491.591,27.199c0.261,0.891,0.407,1.83,0.407,2.803v156.014c0,0.727-0.083,1.435-0.231,2.119L406.45,88.452L491.591,27.199z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M447.994,437.118c-1.499,0-2.921,0.33-4.197,0.921h-4.882c-5.524,0-10.001,4.478-10.001,10.001  c0,5.522,4.477,10.001,10.001,10.001h9.08c5.524,0,10.001-4.478,10.001-10.001v-0.921  C457.995,441.597,453.518,437.118,447.994,437.118z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M447.994,387.114c-5.523,0-10.001,4.478-10.001,10.001v10.001c0,5.522,4.477,10.001,10.001,10.001  c5.524,0,10.001-4.478,10.001-10.001v-10.001C457.995,391.592,453.518,387.114,447.994,387.114z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M398.911,438.039H388.91c-5.523,0-10.001,4.478-10.001,10.001c0,5.522,4.477,10.001,10.001,10.001h10.001  c5.523,0,10.001-4.478,10.001-10.001C408.912,442.518,404.434,438.039,398.911,438.039z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M447.994,237.1c-5.523,0-10.001,4.478-10.001,10.001v10.001c0,5.522,4.477,10.001,10.001,10.001  c5.524,0,10.001-4.478,10.001-10.001v-10.001C457.995,241.579,453.518,237.1,447.994,237.1z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M447.994,287.105c-5.523,0-10.001,4.478-10.001,10.001v10.001c0,5.523,4.477,10.001,10.001,10.001  c5.524,0,10.001-4.478,10.001-10.001v-10.001C457.995,291.583,453.518,287.105,447.994,287.105z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M447.994,337.109c-5.523,0-10.001,4.478-10.001,10.001v10.001c0,5.522,4.477,10.001,10.001,10.001  c5.524,0,10.001-4.478,10.001-10.001V347.11C457.995,341.588,453.518,337.109,447.994,337.109z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M63.96,74.007h10.001c5.523,0,10.001-4.478,10.001-10.001s-4.477-10.001-10.001-10.001H63.96  c-5.523,0-10.001,4.478-10.001,10.001S58.437,74.007,63.96,74.007z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M113.964,74.007h10.001c5.523,0,10.001-4.478,10.001-10.001s-4.477-10.001-10.001-10.001h-10.001  c-5.523,0-10.001,4.478-10.001,10.001S108.442,74.007,113.964,74.007z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M63.96,124.011c5.523,0,10.001-4.478,10.001-10.001v-10.001c0-5.522-4.477-10.001-10.001-10.001  s-10.001,4.478-10.001,10.001v10.001C53.959,119.534,58.437,124.011,63.96,124.011z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M63.96,174.016c5.523,0,10.001-4.478,10.001-10.001v-10.001c0-5.522-4.477-10.001-10.001-10.001  s-10.001,4.478-10.001,10.001v10.001C53.959,169.538,58.437,174.016,63.96,174.016z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M103.533,402.426c-2.64,0-5.21,1.07-7.081,2.93c-1.86,1.86-2.92,4.44-2.92,7.071c0,2.63,1.06,5.21,2.92,7.071  c1.87,1.859,4.45,2.93,7.081,2.93s5.2-1.07,7.071-2.93c1.86-1.86,2.93-4.44,2.93-7.071c0-2.63-1.07-5.21-2.93-7.071  C108.744,403.496,106.163,402.426,103.533,402.426z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
        <path
          d="M258.599,363.243c-15.122-15.121-39.729-15.122-54.853,0.001l-3.433,3.434c-4.768,4.77-12.528,4.769-17.297,0  l-37.692-37.692c-2.31-2.31-3.582-5.382-3.582-8.648c0-3.266,1.272-6.338,3.583-8.648l3.433-3.434  c15.123-15.123,15.123-39.73,0-54.853l-52.695-52.695c-15.123-15.122-39.731-15.122-54.852,0l-12.609,12.608  c-38.136,38.137-38.136,100.19,0,138.326l34.375,34.375c3.905,3.904,10.238,3.904,14.144,0c3.905-3.905,3.905-10.238,0-14.144  l-34.375-34.375c-30.338-30.338-30.338-79.701,0-110.039l12.608-12.608c7.324-7.325,19.241-7.325,26.565,0l52.694,52.694  c7.325,7.324,7.325,19.241,0,26.565l-3.433,3.434c-1.261,1.261-2.397,2.612-3.418,4.032l-41.621-41.621  c-3.905-3.904-10.238-3.904-14.144,0c-3.905,3.905-3.905,10.238,0,14.144l49.962,49.962c0.824,7.199,4.019,13.865,9.22,19.066  l37.692,37.692c12.568,12.566,33.016,12.567,45.584-0.001l3.434-3.434c7.324-7.325,19.241-7.325,26.565,0l52.695,52.694  c3.547,3.548,5.501,8.266,5.501,13.282c0,5.016-1.954,9.734-5.503,13.282l-7.16,7.16l-47.961-47.961  c-3.905-3.904-10.238-3.904-14.144,0c-3.905,3.905-3.905,10.238,0,14.144l47.268,47.268c-30.403,22.039-73.274,19.378-100.652-8.002  l-25.898-25.898c-3.905-3.903-10.238-3.904-14.143,0c-3.905,3.905-3.905,10.238,0,14.144l25.898,25.898  C179.425,502.465,204.473,512,229.52,512s50.095-9.534,69.164-28.603l12.607-12.608c7.327-7.326,11.361-17.066,11.361-27.426  c0-10.361-4.034-20.101-11.36-27.426L258.599,363.243z"
          data-original="#000000"
          className={classes.fillPurple}
          data-old_color="#000000"
        />
      </g>
    </SvgIcon>
  );
};

export default withStyles(headerIconsStyle)(ContactIcon);
