import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import headerIconsStyle from 'assets/jss/material-kit-react/components/headerIconsStyle';

const FAQIcon = ({ classes }) => {
  return (
    <SvgIcon viewBox="0 0 512 512" className={classes.icon}>
      <g>
        <path
          className={classes.fillDarkerGrey}
          d="M286,184H70c-33.137,0-60,26.863-60,60v154c0,33.137,26.863,60,60,60h64.979l-0.901,0.078L178,502  l0.031-44H286c33.137,0,60-26.863,60-60V244C346,210.863,319.137,184,286,184z"
          data-original="#C7EAFF"
          data-old_color="#C7EAFF"
        />
        <path
          d="M178.002,512c-2.603,0-5.16-1.016-7.073-2.929L129.858,468H70c-38.598,0-70-31.402-70-70V244c0-38.598,31.402-70,70-70h101  c5.522,0,10,4.477,10,10s-4.478,10-10,10H70c-27.57,0-50,22.43-50,50v154c0,27.57,22.43,50,50,50h64.979  c4.09,0,7.626,2.461,9.171,6.008l23.868,23.867l0.014-19.882c0.004-5.52,4.479-9.993,10-9.993H286c27.57,0,50-22.43,50-50v-69  c0-5.523,4.478-10,10-10s10,4.477,10,10v69c0,38.598-31.402,70-70,70h-97.976L188,502.007c-0.003,4.043-2.44,7.687-6.177,9.233  C180.588,511.751,179.289,512,178.002,512z"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          className={classes.fillLighterGrey}
          d="M366.655,10h-25.309C266.597,10,206,70.596,206,145.346l0,0c0,74.749,60.596,135.346,135.346,135.346  h25.309c13.854,0,27.22-2.086,39.805-5.953L448,316.255v-62.733c32.794-24.699,54-63.96,54-108.177l0,0  C502,70.596,441.404,10,366.655,10z"
          data-original="#FFBC53"
          data-old_color="#FFBC53"
        />
        <path
          d="M448.002,326.255c-2.602,0-5.158-1.016-7.071-2.927l-37.387-37.366c-12,3.14-24.381,4.729-36.89,4.729h-25.309  C261.202,290.691,196,225.49,196,145.346S261.202,0,341.346,0h25.309C446.798,0,512,65.202,512,145.346  c0,23.216-5.313,45.405-15.794,65.951c-9.228,18.09-22.377,34.283-38.206,47.102v57.856c0,4.044-2.436,7.69-6.172,9.238  C450.591,326.006,449.291,326.255,448.002,326.255z M406.459,264.738c2.612,0,5.166,1.025,7.07,2.927L438,292.123v-38.6  c0-3.141,1.476-6.099,3.983-7.988C473.77,221.595,492,185.077,492,145.346C492,76.23,435.771,20,366.655,20h-25.309  C272.23,20,216,76.23,216,145.346s56.229,125.346,125.346,125.346h25.309c12.561,0,24.965-1.854,36.868-5.512  C404.489,264.883,405.479,264.738,406.459,264.738z"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="M356.8,186.774c-5.522,0-10-4.477-10-10v-24.025c0-8.957,6.13-16.585,14.908-18.549  c10.834-2.424,18.141-12.266,17.373-23.403c-0.76-11.027-9.649-19.916-20.677-20.676c-6.29-0.434-12.268,1.679-16.842,5.948  c-4.578,4.273-7.1,10.074-7.1,16.333c0,5.523-4.478,10-10,10s-10-4.477-10-10c0-11.691,4.903-22.973,13.453-30.954  c8.669-8.093,19.995-12.096,31.863-11.281c20.936,1.443,37.812,18.319,39.255,39.254c1.435,20.821-12.084,39.259-32.234,44.127  v23.226C366.8,182.297,362.323,186.774,356.8,186.774z"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="M356.8,226.33c-2.63,0-5.21-1.06-7.07-2.92c-1.859-1.86-2.93-4.44-2.93-7.08c0-2.63,1.07-5.21,2.93-7.07  s4.44-2.93,7.07-2.93s5.21,1.07,7.07,2.93c1.859,1.86,2.93,4.44,2.93,7.07c0,2.64-1.07,5.21-2.93,7.08  C362.01,225.27,359.43,226.33,356.8,226.33z"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="M275,330H64c-5.522,0-10-4.477-10-10s4.478-10,10-10h211c5.522,0,10,4.477,10,10S280.523,330,275,330z"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="M275,386c-2.63,0-5.21-1.07-7.07-2.93S265,378.63,265,376s1.069-5.21,2.93-7.07c1.86-1.86,4.44-2.93,7.07-2.93  s5.21,1.07,7.069,2.93c1.86,1.86,2.931,4.44,2.931,7.07c0,2.64-1.07,5.21-2.931,7.07C280.21,384.93,277.63,386,275,386z"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="M235.667,386H64c-5.522,0-10-4.477-10-10s4.478-10,10-10h171.667c5.522,0,10,4.477,10,10S241.19,386,235.667,386z"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="M210,274H64c-5.522,0-10-4.477-10-10s4.478-10,10-10h146c5.522,0,10,4.477,10,10S215.523,274,210,274z"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
      </g>
    </SvgIcon>
  );
};

export default withStyles(headerIconsStyle)(FAQIcon);
