import React, { useState } from 'react';
import { navigate } from 'gatsby';
import linkResolver from 'utils/linkResolver';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import languageSwitcherStyle from 'assets/jss/material-kit-react/components/languageSwitcherStyle';

const LanguageSwitcher = ({ activeDocMeta, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = event => {
    navigate(event.target.getAttribute('value'));
    setAnchorEl(null);
  };


  const currentLang = activeDocMeta.lang;

  const currentLangOption = (
    <MenuItem value={currentLang}>{currentLang.slice(0, 2).toUpperCase()}</MenuItem>
  );

  const alternateLangOptions = activeDocMeta.alternateLanguages.map((altLang, index) => (
    <MenuItem value={linkResolver(altLang)} key={`alt-lang-${index}`} onClick={handleMenuItemClick}>
      {altLang.lang.slice(0, 2).toUpperCase()}
    </MenuItem>
  ));

  return (
    <div>
      <Button
        className={classes.navLink}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {currentLang.slice(0, 2).toUpperCase()}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {currentLangOption}
        {alternateLangOptions}
      </Menu>
    </div>
  );
};

export default withStyles(languageSwitcherStyle)(LanguageSwitcher);
