import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import headerIconsStyle from 'assets/jss/material-kit-react/components/headerIconsStyle';

const ServicesIcon = ({ classes }) => {
  return (
    <SvgIcon viewBox="0 0 512 512" className={classes.icon}>
      <g>
        <g id="Filled_Outline" data-name="Filled Outline">
          <path
            d="m24 232 232-208 232 208v48l-232-208-232 208z"
            fill="#9fe4ed"
            data-original="#9FE4ED"
            data-old_color="#9fe4ed"
            className={classes.fillWhite}
          />
          <path
            d="m440 236.97v251.03h-368v-251.03l184-164.97 117.12 105.01z"
            fill="#60d1e0"
            data-original="#60D1E0"
            data-old_color="#60d1e0"
            className={classes.fillLighterGrey}
          />
          <path
            d="m440 236.97v251.03h-368v-45.24c97.75-37.51 225.66-117.81 301.12-265.75z"
            fill="#5ac6d4"
            data-original="#5AC6D4"
            data-old_color="#5ac6d4"
            className={classes.fillGrey}
          />
          <path
            d="m72 236.965v251.035h40v-286.896z"
            fill="#9fe4ed"
            data-original="#9FE4ED"
            data-old_color="#9fe4ed"
            className={classes.fillWhite}
          />
          <path
            d="m400 488h40v-251.035l-40-35.861z"
            fill="#9fe4ed"
            data-original="#9FE4ED"
            data-old_color="#9fe4ed"
            className={classes.fillWhite}
          />
          <rect
            fill="#348fd9"
            height="80"
            rx="32"
            width="64"
            x="224"
            y="192"
            data-original="#348FD9"
            data-old_color="#348fd9"
            className={classes.fillWhite}
          />
          <path
            d="m296 312 72-80-24-24-56 64h-64l-56-64-24 24 72 80v152l-16 24h112l-16-24z"
            fill="#348fd9"
            data-original="#348FD9"
            data-old_color="#348fd9"
            className={classes.fillWhite}
          />
          <path
            d="m493.34 226.044-37.972-34.044h3.273v-17.376a24.017 24.017 0 0 0 7.581-4.391l15.06 8.695 8-13.856-15.049-8.688a23.757 23.757 0 0 0 0-8.768l15.049-8.688-8-13.856-15.06 8.695a24.017 24.017 0 0 0 -7.581-4.391v-17.376h-16v17.376a24.028 24.028 0 0 0 -7.581 4.391l-15.06-8.695-8 13.856 15.049 8.688a23.757 23.757 0 0 0 0 8.768l-6.938 4.006-158.771-142.346a8 8 0 0 0 -10.68 0l-232 208a8 8 0 0 0 -2.66 5.956v48a8 8 0 0 0 13.34 5.956l34.66-31.074v233.118a8 8 0 0 0 8 8h368a8 8 0 0 0 8-8v-233.118l34.66 31.074a8 8 0 0 0 13.34-5.956v-48a8 8 0 0 0 -2.66-5.956zm-42.699-82.044a8 8 0 1 1 -8 8 8.009 8.009 0 0 1 8-8zm-15.581 26.233a24.028 24.028 0 0 0 7.581 4.391v5.965l-10-8.962zm-118.779 309.767-12.281-18.422v-146.508l69.946-77.718a8 8 0 0 0 -.289-11.009l-24-24a8 8 0 0 0 -11.678.389l-44.271 50.6a39.8 39.8 0 0 0 2.292-13.332v-16a40 40 0 0 0 -80 0v16a39.8 39.8 0 0 0 2.292 13.327l-44.271-50.595a8 8 0 0 0 -11.678-.389l-24 24a8 8 0 0 0 -.289 11.009l69.946 77.718v146.508l-12.281 18.422h-75.719v-275.325l136-121.931 136 121.931v275.325zm-84.281-240v-16a24 24 0 0 1 48 0v16a24 24 0 0 1 -48 0zm-152 240v-239.463l24-21.516v260.979zm184 0v-104h-16v104h-33.052l7.708-11.562a8 8 0 0 0 1.344-4.438v-152a8 8 0 0 0 -2.054-5.352l-66.922-74.358 12.586-12.59 50.369 57.565a8 8 0 0 0 6.021 2.735h64a8 8 0 0 0 6.021-2.732l50.369-57.568 12.586 12.587-66.922 74.358a8 8 0 0 0 -2.054 5.355v152a8 8 0 0 0 1.344 4.438l7.708 11.562zm168 0h-24v-260.979l24 21.516zm48-217.917-218.66-196.04a8 8 0 0 0 -10.68 0l-218.66 196.04v-26.511l224-200.828 224 200.828z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m425.282 29.072-15.06 8.7a24.017 24.017 0 0 0 -7.581-4.391v-17.381h-16v17.376a24.028 24.028 0 0 0 -7.581 4.391l-15.06-8.695-8 13.856 15.049 8.688a23.757 23.757 0 0 0 0 8.768l-15.049 8.688 8 13.856 15.06-8.695a24.028 24.028 0 0 0 7.581 4.391v17.376h16v-17.376a24.017 24.017 0 0 0 7.581-4.391l15.06 8.695 8-13.856-15.049-8.688a23.757 23.757 0 0 0 0-8.768l15.049-8.688zm-30.641 34.928a8 8 0 1 1 8-8 8.009 8.009 0 0 1 -8 8z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m89.282 85.072-15.06 8.695a24.017 24.017 0 0 0 -7.581-4.391v-17.376h-16v17.376a24.028 24.028 0 0 0 -7.581 4.391l-15.06-8.695-8 13.856 15.049 8.688a23.757 23.757 0 0 0 0 8.768l-15.049 8.688 8 13.856 15.06-8.695a24.028 24.028 0 0 0 7.581 4.391v17.376h16v-17.376a24.017 24.017 0 0 0 7.581-4.391l15.06 8.695 8-13.856-15.049-8.688a23.757 23.757 0 0 0 0-8.768l15.049-8.688zm-30.641 34.928a8 8 0 1 1 8-8 8.009 8.009 0 0 1 -8 8z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m16 16h16v16h-16z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m88 40h16v16h-16z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m464 64h16v16h-16z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m320 32h16v16h-16z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m480 328h16v16h-16z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m16 376h16v16h-16z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m32 312h16v16h-16z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m32 440h16v16h-16z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
          <path
            d="m464 440h16v16h-16z"
            data-original="#000000"
            className={classes.fillPurple}
            data-old_color="#000000"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default withStyles(headerIconsStyle)(ServicesIcon);
