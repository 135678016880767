import React, { useEffect, useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { RichText } from 'prismic-reactjs';

import HeaderLinks from 'components/Header/HeaderLinks';

// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// core components
import headerStyle from 'assets/jss/material-kit-react/components/headerStyle';

import servitoursLogo from 'assets/img/servitours-logo.png';

const Header = ({
    changeColorOnScroll,
    color,
    classes,
    menu,
    tours,
    activeDocMeta,
    leftLinks,
    fixed,
    absolute,
  }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const settings = {
    className: 'home-slider',
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const headerColorChange = () => {
    const windowsScrollTop = typeof window !== 'undefined' && window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
      document.body
        .getElementsByTagName('header')[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
      document.body
        .getElementsByTagName('header')[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return () => {
      if (changeColorOnScroll && typeof window !== 'undefined') {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  }, []);

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const brandComponent = <img src={servitoursLogo} className={classes.logo} alt="bulamadım" />;

  return (
    <AppBar className={appBarClasses}>
      <div className={classes.promoteBar}>
        <Carousel {...settings}>
          {tours.map((document, index) => (
            <AniLink key={index} to={document.destination.document.url} className={classes.link} paintDrip hex="#819E84">
              <p>{RichText.asText(document.message.raw)}</p>
            </AniLink>
          ))}
        </Carousel>
      </div>
      <Toolbar className={classes.container}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks activeDocMeta={activeDocMeta} menu={menu} />
        </Hidden>
        <Hidden mdUp>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            <HeaderLinks activeDocMeta={activeDocMeta} menu={menu} />
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

export default withStyles(headerStyle)(Header);
