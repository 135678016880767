import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import headerIconsStyle from 'assets/jss/material-kit-react/components/headerIconsStyle';

const NewsIcon = ({ classes }) => {
  return (
    <SvgIcon viewBox="0 0 512 512" className={classes.icon}>
      <g>
        <path
          d="m67.507812 52.28125v-42.007812h336.804688v439.039062h-46.84375"
          fill="#dff6fc"
          data-original="#DFF6FC"
          data-old_color="#dff6fc"
          className={classes.fillGrey}
        />
        <path
          d="m10.273438 62.6875h336.804687v144.113281h-336.804687zm0 0"
          fill="#f9f9f9"
          data-original="#F9F9F9"
          data-old_color="#f9f9f9"
          className={classes.fillWhite}
        />
        <path
          d="m10.273438 206.800781h336.804687v294.925781h-336.804687zm0 0"
          fill="#f9f9f9"
          data-original="#F9F9F9"
          data-old_color="#f9f9f9"
          className={classes.fillWhite}
        />
        <path
          d="m53.511719 246.785156h114.453125v168.667969h-114.453125zm0 0"
          fill="#79b2ff"
          data-original="#79B2FF"
          data-old_color="#79b2ff"
          className={classes.fillDarkerGrey}
        />
        <path
          d="m0 501.726562c0 5.671876 4.601562 10.273438 10.273438 10.273438h336.804687c5.671875 0 10.273437-4.601562 10.273437-10.273438v-42.140624h46.960938c5.675781 0 10.273438-4.601563 10.273438-10.273438v-319.703125c0-13.207031-20.542969-13.191406-20.542969 0v309.433594h-36.691407v-376.355469c0-5.675781-4.601562-10.273438-10.273437-10.273438h-269.296875v-31.871093h316.257812v26.382812c0 13.210938 20.546876 13.191407 20.546876 0v-36.652343c0-5.671876-4.601563-10.273438-10.273438-10.273438h-336.804688c-5.671874 0-10.273437 4.601562-10.273437 10.273438v42.140624h-46.960937c-5.671876 0-10.273438 4.597657-10.273438 10.273438zm20.546875-428.769531h316.257813v123.574219h-316.257813zm316.257813 418.5h-316.257813v-274.382812h316.257813zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m110.3125 105.816406c-3.488281-1.832031-12.476562-1.863281-12.476562 3.816406v24.046876l-11.429688-21.945313c-2.519531-4.878906-17.863281-12.472656-17.863281-2.019531v52.753906c0 6.871094 14.925781 6.871094 14.925781 0v-24.21875l13.746094 25.207031c2.453125 4.511719 15.546875 6.496094 15.546875-.988281v-52.835938c0-1.65625-.847657-2.976562-2.449219-3.816406zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m159.753906 118.054688c6.550782 0 6.671875-13.355469 0-13.355469h-29.375c-2.96875 0-6.636718 1.589843-6.636718 5.015625v52.753906c0 3.425781 3.667968 5.015625 6.636718 5.015625h29.375c6.671875 0 6.550782-13.355469 0-13.355469h-21.085937v-12.066406h11.351562c6.496094 0 6.3125-12.023438 0-12.023438h-11.351562v-11.984374zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m242.414062 106.421875c-2.980468-1.753906-10.003906-3.257813-11.28125 1.425781l-11.441406 38.871094-5.628906-24.253906c-1.207031-5.75-13.222656-5.621094-14.382812-.019532l-5.636719 24.273438-11.433594-38.851562c-1.832031-6.683594-16.453125-2.191407-14.261719 4.410156l16.417969 50.667968c2.148437 6.769532 17.085937 7.175782 18.816406-.089843l3.285157-15.085938 3.292968 15.101563c1.757813 7.390625 16.453125 6.667968 18.808594.082031l16.417969-50.667969c.796875-2.414062-1.015625-4.710937-2.972657-5.863281zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m284.871094 133.683594c-1.953125-1.433594-4.125-2.773438-6.445313-3.984375-2.722656-1.417969-12.417969-3.847657-12.523437-8.1875-.140625-5.832031 11.148437-3.816407 13.867187-2.484375 12.042969 5.910156 13.65625-12.847656-2.035156-14.5625-6.9375-.761719-14.308594.179687-20.136719 4.285156-7.273437 5.128906-8.71875 16.679688-3.757812 23.894531 3.519531 5.132813 9.179687 7.75 14.703125 10.203125 10.566406 4.691406 9.355469 16.519532-2.847657 11.191406-5.308593-2.308593-16.855468-10.785156-15.800781 2.96875.671875 8.726563 13.828125 11.304688 20.507813 11.304688 6.382812 0 11.628906-1.6875 15.59375-5.011719 8.671875-7.265625 7.980468-22.9375-1.125-29.617187zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m53.511719 425.726562h114.453125c5.671875 0 10.273437-4.601562 10.273437-10.273437v-168.667969c0-5.671875-4.601562-10.269531-10.273437-10.269531h-114.453125c-5.671875 0-10.273438 4.597656-10.273438 10.269531v168.667969c0 5.675781 4.601563 10.273437 10.273438 10.273437zm10.273437-168.667968h93.90625v148.125h-93.90625zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m211.445312 268.828125h45.730469c13.1875 0 13.207031-20.546875 0-20.546875h-45.730469c-13.1875 0-13.207031 20.546875 0 20.546875zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m211.445312 305.109375h87.347657c13.1875 0 13.207031-20.542969 0-20.542969h-87.347657c-13.1875 0-13.207031 20.542969 0 20.542969zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m211.445312 341.394531h87.347657c13.1875 0 13.207031-20.546875 0-20.546875h-87.347657c-13.1875 0-13.207031 20.546875 0 20.546875zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m211.445312 377.675781h87.347657c13.1875 0 13.207031-20.542969 0-20.542969h-87.347657c-13.1875 0-13.207031 20.542969 0 20.542969zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m211.445312 413.957031h50.199219c13.191407 0 13.207031-20.542969 0-20.542969h-50.199219c-13.1875 0-13.207031 20.542969 0 20.542969zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m300.707031 449.003906h-242.460937c-13.191406 0-13.207032 20.542969 0 20.542969h242.460937c13.1875 0 13.207031-20.542969 0-20.542969zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m298.792969 268.832031c5.582031 0 10.269531-4.691406 10.273437-10.269531.003906-5.597656-4.679687-10.277344-10.273437-10.277344s-10.273438 4.679688-10.269531 10.277344c0 5.578125 4.6875 10.269531 10.269531 10.269531zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
        <path
          d="m398.402344 80.390625c-8.046875 5.617187-3.878906 18.664063 5.90625 18.664063 4.675781 0 8.863281-3.292969 9.980468-7.828126 2.230469-9.066406-8.304687-16.128906-15.886718-10.835937zm0 0"
          data-original="#000000"
          data-old_color="#000000"
          className={classes.fillPurple}
        />
      </g>
    </SvgIcon>
  );
};

export default withStyles(headerIconsStyle)(NewsIcon);
